import makeStyles from '@mui/styles/makeStyles';

export const useChangeUnitButtonStyles = makeStyles((theme) => ({
  changeUnitAction: {
    color: theme.palette.baseColors.deepSeaNavy.c100,
    borderRadius: '0',
    marginLeft: '0',
    '&:hover': {
      filter: 'brightness(95%)',
    },
  },
}));
