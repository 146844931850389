import React, { useCallback, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { isImperial } from '../../../utils/distanceUnits';
import { useChangeUnitButtonStyles } from './ChangeUnitsButton.style';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';

const CheckMark = () => (
  <ListItemIcon>
    <Check />
  </ListItemIcon>
);

export const ChangeUnitsButton = ({
  rulerActive,
  mapLoaded,
  onDistanceUnitsChange,
  distanceUnits,
}) => {
  const classes = useChangeUnitButtonStyles();

  const setChangeUnitButton = useCallback(
    (node) => {
      if (mapLoaded && node) {
        const groupContainer = document.getElementsByClassName('mapboxgl-ctrl-group')[0];
        groupContainer.prepend(node);
      }
    },
    [mapLoaded],
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openMenu = useCallback((event) => setAnchorEl(event.currentTarget), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);

  const imperialUnitsChosen = isImperial(distanceUnits);

  const onMenuOptionClick = useCallback(() => {
    onDistanceUnitsChange();
    closeMenu();
  }, [onDistanceUnitsChange, closeMenu]);

  return (
    <div ref={setChangeUnitButton}>
      <Tooltip
        title={rulerActive ? 'Map settings - disabled due to active ruler' : 'Map settings'}
        aria-label="settings tooltip"
      >
        <span>
          <IconButton
            id="settings-button"
            aria-label="settings"
            aria-controls={open ? 'settings-menu' : undefined}
            aria-haspopup={'true'}
            aria-expanded={open ? 'true' : undefined}
            onClick={openMenu}
            edge="start"
            className={classes.changeUnitAction}
            size="large"
            disabled={rulerActive}
          >
            <SettingsIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'settings-button',
        }}
      >
        <MenuItem disabled={imperialUnitsChosen} onClick={onMenuOptionClick}>
          Imperial
          {!!imperialUnitsChosen && <CheckMark />}
        </MenuItem>
        <MenuItem disabled={!imperialUnitsChosen} onClick={onMenuOptionClick}>
          Metric
          {!imperialUnitsChosen && <CheckMark />}
        </MenuItem>
      </Menu>
    </div>
  );
};
