import { useState } from 'react';
import { LOCAL_STORAGE_KEY_MAP_UNITS } from './constants';

export const DISTANCE_IMPERIAL = 'imperial';
export const DISTANCE_METRIC = 'metric';
export const DEFAULT_DISTANCE_UNIT = DISTANCE_IMPERIAL;

export const isImperial = (metric) => metric === DISTANCE_IMPERIAL;

export const getDistanceUnitsShort = (metric) => {
  if (isImperial(metric)) {
    return 'mi';
  }
  return 'km';
};

export const getDistanceUnitsLong = (metric) => {
  if (isImperial(metric)) {
    return 'miles';
  }
  return 'kilometers';
};

const getMapUnitFromLocalStorage = () => {
  const localStorageUnit = localStorage.getItem(LOCAL_STORAGE_KEY_MAP_UNITS);
  if (localStorageUnit) {
    return localStorageUnit;
  }
  return DEFAULT_DISTANCE_UNIT;
};

const setMapUnitToLocalStorage = (unit) => {
  localStorage.setItem(LOCAL_STORAGE_KEY_MAP_UNITS, unit);
};

export const useDistanceUnits = () => {
  const [distanceUnits, setDistanceUnits] = useState(() => getMapUnitFromLocalStorage());

  const changeDistanceUnits = (newUnits) => {
    setMapUnitToLocalStorage(newUnits);
    setDistanceUnits(newUnits);
  };

  return { distanceUnits, setDistanceUnits: changeDistanceUnits };
};

export const getOtherDistanceUnits = (metric) => {
  if (isImperial(metric)) {
    return DISTANCE_METRIC;
  }
  return DISTANCE_IMPERIAL;
};
