import { MAP_SOURCE_IDS } from '../Utils/constants';
import React, { useCallback, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import TimelineIcon from '@mui/icons-material/Timeline';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import { rulerEvent } from '../Utils/utils';
import { useRulerButtonStyles } from './RulerButton.style';
import { geojson, linestring } from '../map.constants';

export const RulerButton = ({
  map,
  rulerActive = false,
  setRulerActive,
  mapLoaded,
  distanceUnits,
}) => {
  const classes = useRulerButtonStyles();

  // prepend the ruler control to the mapbox controls if ruler is enabled
  const setRuler = useCallback(
    (node) => {
      if (mapLoaded && node) {
        // mapbox control group (bottom-right nav controls) container
        const groupContainer = document.getElementsByClassName('mapboxgl-ctrl-group')[0];
        groupContainer.prepend(node);
      }
    },
    [mapLoaded],
  );

  useEffect(() => {
    if (map) {
      map.toggleMapRuler(rulerActive);
    }
  }, [rulerActive]);

  const onRulerClick = () => {
    map.onClickRulerCallback = rulerEvent(map, geojson, linestring, distanceUnits);
    setRulerActive(true);
  };

  const onRulerClose = () => {
    geojson.features = [];
    linestring.geometry.coordinates = [];
    map.getSource(MAP_SOURCE_IDS.geojson).setData(geojson);
    setRulerActive(false);
  };

  const onRulerButtonClick = () => (rulerActive ? onRulerClose() : onRulerClick());

  return (
    <div ref={setRuler}>
      <Tooltip title="Ruler" aria-label="ruler tooltip">
        <IconButton
          id="ruler-button"
          onClick={onRulerButtonClick}
          edge="start"
          className={clsx(classes.rulerAction, {
            [classes.rulerActionSelected]: rulerActive,
          })}
          size="large"
        >
          <TimelineIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};
